<template>
  <div class="columns is-centered is-marginless" style="height: 100%">
    <div
      class="column is-6"
      style="height: 100%"
    >
      <div class="column is-narrow">
        <h1 class="is-size-4 has-text-weight-medium">
          Profile
        </h1>
      </div>
      <div class="column is-narrow">
        <form @submit.prevent="create" class="mb-5">
          <b-field
            custom-class=""
            :type="error.email ? 'is-danger' : ''"
            :message="error.email"
          >
            <b-input
              placeholder="Email"
              type="email"
              custom-class="custum-input"
              v-model="user.email"
              disabled>
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.firstName ? 'is-danger' : ''"
            :message="error.firstName"
          >
            <b-input
              placeholder="FirstName"
              custom-class="custum-input"
              v-model="user.firstName"
              :disabled="notEdit">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.lastName ? 'is-danger' : ''"
            :message="error.lastName"
          >
            <b-input
              placeholder="LastName"
              custom-class="custum-input"
              v-model="user.lastName"
              :disabled="notEdit">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.mobileNumber ? 'is-danger' : ''"
            :message="error.mobileNumber"
          >
            <b-input
              placeholder="MobileNumber"
              custom-class="custum-input"
              type="tel"
              v-model="user.mobileNumber"
              :disabled="notEdit">
            </b-input>
          </b-field>
        </form>
        <div class="columns is-centered" v-if="notEdit">
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="notEdit = false"
              class="has-text-weight-bold"
            >
              Edit
            </b-button>
          </div>
        </div>
        <div class="columns is-centered" v-else>
          <div class="column">
            <b-button
              expanded
              @click="notEdit = true"
              class="has-text-weight-bold has-text-primary"
            >
              Cancel
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="create"
              class="has-text-weight-bold"
            >
              Update
            </b-button>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { validation } from '@/services/validation';
import { UpdateUser } from '@/api/user';

export default {
  metaInfo: {
    title: 'Profile',
  },
  components: {},
  data() {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      error: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      notEdit: true,
    };
  },
  watch: {
    userStore() {
      this.setUserEdit();
    },
  },
  computed: {
    ...mapGetters({
      userStore: 'user',
    }),
  },
  methods: {
    ...mapMutations({
      setUser: 'SET_USER',
    }),
    async create() {
      if (!validation(this.user, this.error, 2000)) return '';
      const loadingComponent = this.$buefy.loading.open();
      try {
        const res = (await UpdateUser(this.user)).data;
        this.setUser(res.user);
        loadingComponent.close();
        this.$buefy.toast.open({
          message: 'User successfully created',
          type: 'is-success',
          position: 'is-bottom',
        });
        this.notEdit = true;
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
    setUserEdit() {
      this.user = {
        email: this.userStore.email,
        firstName: this.userStore.firstName,
        lastName: this.userStore.lastName,
        mobileNumber: this.userStore.mobileNumber,
      };
    },
  },
  mounted() {
    this.setUserEdit();
  },
};
</script>
